import React, { useState } from "react"
import styled from "styled-components"

const initialValues = {
  subject: "",
  name: "",
  contacts: "",
  text: "",
}

export const ContactForm = () => {
  const [formData, setFormData] = useState(initialValues)
  const [isFormSubmitted, setFormSubmittingState] = useState(false)

  const handleFormInput = (e, value) => {
    setFormData({ ...formData, [value]: e.currentTarget.value })
  }

  const encodeFormData = data => {
    return Object.keys(data)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
      .join("&")
  }

  const onFormSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encodeFormData({
        "form-name": form.getAttribute("name"),
        ...formData,
      }),
    })
      .then(() => {
        setFormSubmittingState(true)
        setFormData(initialValues)
      })
      .catch(error => console.log(error))
  }

  return (
    <Form
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      onSubmit={onFormSubmit}
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />

      <h2>Отправьте нам сообщение</h2>
      <input
        type="text"
        name="subject"
        value={formData.subject}
        onChange={e => handleFormInput(e, "subject")}
        placeholder="Тема сообщения"
      ></input>
      <input
        type="text"
        placeholder="Как Вас зовут?"
        name="name"
        value={formData.name}
        onChange={e => handleFormInput(e, "name")}
      ></input>
      <input
        type="text"
        name="contacts"
        required
        value={formData.contacts}
        onChange={e => handleFormInput(e, "contacts")}
        placeholder="Номер телефона или email"
      ></input>
      <textarea
        rows="15"
        name="text"
        value={formData.text}
        onChange={e => handleFormInput(e, "text")}
      ></textarea>
      {!isFormSubmitted && <button type="submit">Отправить сообщение</button>}
      {isFormSubmitted && <Success>Сообщение успешно отправлено!</Success>}
    </Form>
  )
}

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 40px;

  h2 {
    font-size: 32px;
  }

  input[type="text"] {
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 16px 24px;
    margin-bottom: 16px;
  }

  textarea {
    border: 1px solid #eee;
    border-radius: 4px;
    padding: 16px 24px;
    margin-bottom: 16px;
  }

  button {
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.primary};
    color: #fff;
    border: none;
    padding-top: 16px;
    padding-bottom: 16px;

    font-weight: bold;
  }
`

const Success = styled.div`
  background-color: #62d2a2;
  padding: 16px;
  text-align: center;
  color: #fff;
  font-weight: bold;
  border-radius: 4px;
`
