import React from "react"
import styled from "styled-components"
import { StaticQuery, graphql } from "gatsby"

import { Layout } from "src/components/Layout"
import { Helmet } from "src/components/Helmet"
import { Container } from "src/components/Container"
import { ContactForm } from "src/components/ContactForm"

const ContactPage = () => (
  <>
    <Helmet title="Связаться с нами"></Helmet>
    <StaticQuery
      query={query}
      render={({
        datoCmsContactUsPage: {
          title,
          descriptionNode: {
            childMarkdownRemark: { html },
          },
        },
      }) => (
        <Layout>
          <Container>
            <Inner>
              <Content>
                <Heading>
                  <h1>{title}</h1>
                  <div dangerouslySetInnerHTML={{ __html: html }}></div>
                </Heading>
                <ContactForm></ContactForm>
              </Content>

              <Map>
                <iframe
                  src="https://api-maps.yandex.ru/frame/v1/-/CGSNQHNV?"
                  frameBorder="0"
                  title="map"
                ></iframe>
              </Map>
            </Inner>
          </Container>
        </Layout>
      )}
    ></StaticQuery>
  </>
)

export default ContactPage

const query = graphql`
  query {
    datoCmsContactUsPage {
      title
      descriptionNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

const Heading = styled.div`
  margin-bottom: 32px;

  h1 {
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 0;
  }
`

const Inner = styled.div`
  background-color: #fff;
  border-radius: 6px;
  padding: 32px;
  margin-bottom: 32px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

const Map = styled.div`
  height: auto;
  flex-basis: 47%;

  iframe {
    border-radius: 6px;
    width: 100%;
    height: 100%;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    flex-basis: 100%;
    margin-top: 32px;

    height: 320px;
  }
`

const Content = styled.div`
  flex-basis: 50%;

  @media (max-width: ${({ theme }) => theme.breakpoints.l}) {
    flex-basis: 100%;
  }
`
